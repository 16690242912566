import FLCloudFruitLogo from '@components/ui-components/icons/fl-cloud-fruit-logo'
import FLCloudFontLogo from '@components/ui-components/icons/fl-cloud-font-logo'
import { useCallback, useContext, useMemo } from 'react'
import { AppStoreContext, LoadingState } from '@store/RootStore'
import { observer } from 'mobx-react'
import { LOADER_SIZE, LOGO_FRUIT_HEIGHT, LOGO_FRUIT_WIDTH, LOGO_TEXT_HEIGHT, LOGO_TEXT_WIDTH } from '@constants/dimensions'
import { LoginState } from '@store/AuthStore'

export const ProgressAnimation = () => {
    const appStore = useContext(AppStoreContext)
    const { auth: authStore, loadingState, scale } = appStore
    const state = authStore.state

    const cancelLogin = useCallback(() => {
        authStore.setState(LoginState.loggedOut)
    }, [])

    const fruitLogoWidth = useMemo(() => {
        return LOGO_FRUIT_WIDTH * scale
    }, [scale])

    const fruitLogoHeight = useMemo(() => {
        return LOGO_FRUIT_HEIGHT * scale
    }, [scale])

    const textLogoWidth = useMemo(() => {
        return LOGO_TEXT_WIDTH * scale
    }, [scale])

    const textLogoHeight = useMemo(() => {
        return LOGO_TEXT_HEIGHT * scale
    }, [scale])

    const loaderSize = useMemo(() => {
        return LOADER_SIZE * scale
    }, [scale])

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-flp-primary-black z-50">
            <div className="flp-flex-centered flex-col h-screen">
                <div className="relative mb-3 h-14 w-full gap-4 flp-flex-centered">
                    <div className="z-10 bg-flp-primary-black opacity-1">
                        <FLCloudFruitLogo style={{ width: `${fruitLogoWidth}px`, height: `${fruitLogoHeight}px` }} />
                    </div>
                    <div className="z-0 flex flex-col items-center">
                        <FLCloudFontLogo style={{ width: `${textLogoWidth}px`, height: `${textLogoHeight}px` }} />
                    </div>
                </div>
                <div>
                    <h1 className="text-25px tracking-wide">Instruments and Effects</h1>
                </div>
                <div className="flp-flex-centered flex-col gap-3 h-110px pt-3">
                    <img
                        crossOrigin="anonymous"
                        src="/images/loading-animation.svg"
                        style={{ width: `${loaderSize}px`, height: `${loaderSize}px` }}
                        alt="downloading-animation"
                    />

                    <div className="flex">
                        {loadingState === LoadingState.BOOT && 'Starting up'}
                        {loadingState === LoadingState.CHECKING_AUTH && (state === 'checking' || state === 'loggedIn') && 'Authenticating...'}
                        {loadingState === LoadingState.CHECKING_AUTH && state === 'loggingIn' && 'Logging in'}
                        {loadingState === LoadingState.CHECKING_VERSION && 'Checking latest version'}
                        {loadingState === LoadingState.FETCHING_PLUGINS && 'Loading plugins'}
                        {loadingState === LoadingState.LOADED && state === 'loggingOut' && 'Logging out'}
                        {loadingState === LoadingState.LOADED && state === 'loggedOut' && 'Logged out'}
                        {state === LoginState.loggingIn && authStore.loggingInWithToken && (
                            <div className="flex flex-col items-center">
                                <span className="text-center">Waiting for image-line.com</span>
                                <button
                                    className="flp-button w-115px py-1 bg-flp-button-grey-background text-flp-button-premium-text-highlight border-flp-button-grey-background hover:bg-flp-button-premium-bg-highlight mt-6 hover:cursor-pointer"
                                    onClick={cancelLogin}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ProgressAnimation)
