import FLCloudFontLogo from '@components/ui-components/icons/fl-cloud-font-logo'
import FLCloudFruitLogo from '@components/ui-components/icons/fl-cloud-fruit-logo'
import { LOGO_FRUIT_HEIGHT, LOGO_FRUIT_WIDTH, LOGO_TEXT_HEIGHT, LOGO_TEXT_WIDTH } from '@constants/dimensions'
import { AppStoreContext } from '@store/RootStore'
import { useContext, useMemo } from 'react'

export const Login = () => {
    const appStore = useContext(AppStoreContext)
    const { auth: authStore, scale } = appStore

    const fruitLogoWidth = useMemo(() => {
        return LOGO_FRUIT_WIDTH * scale
    }, [scale])

    const fruitLogoHeight = useMemo(() => {
        return LOGO_FRUIT_HEIGHT * scale
    }, [scale])

    const textLogoWidth = useMemo(() => {
        return LOGO_TEXT_WIDTH * scale
    }, [scale])

    const textLogoHeight = useMemo(() => {
        return LOGO_TEXT_HEIGHT * scale
    }, [scale])

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-flp-primary-black">
            <div className="flp-flex-centered flex-col h-screen">
                <div className="relative overflow-hidden mb-3 h-16 left-0 w-260px">
                    <div className="absolute z-10 bg-flp-primary-black opacity-1">
                        <FLCloudFruitLogo style={{ width: `${fruitLogoWidth}px`, height: `${fruitLogoHeight}px` }} />
                    </div>
                    <div className="absolute z-0 overflow-hidden h-10 mt-4 right-0">
                        <FLCloudFontLogo style={{ width: `${textLogoWidth}px`, height: `${textLogoHeight}px` }} />
                    </div>
                </div>
                <div>
                    <h1 className="text-25px font-gilroyLight tracking-wide">Sign in or create an account to get started</h1>
                </div>
                <button
                    className="flp-button w-115px bg-flp-button-grey-background text-flp-button-premium-text-highlight border-flp-button-grey-background hover:bg-flp-button-premium-bg-highlight mt-6"
                    onClick={authStore.login}>
                    Sign in
                </button>
            </div>
        </div>
    )
}
